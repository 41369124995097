<template>
    <v-row class="mt-3">
        <v-card hover
                class="ma-2 pa-4 settings-card">
            <v-alert :value="!!passwordError"
                    dismissible
                    type="error">
                {{ passwordError }}
            </v-alert>

            <v-card-title primary-title>
                <v-icon large class="mr-3">mdi-key-variant</v-icon>
                <h3 class="headline mb-0">{{ $t('settings.password.change') }}</h3>
            </v-card-title>

            <v-card-title>
                <v-form ref="form"
                        style="width:100%"
                        v-model="valid"
                        lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="password.old"
                                        :append-icon="showPasswdOld ? 'mdi-eye_off' : 'mdi-eye'"
                                        :rules="[rules.required]"
                                        :type="showPasswdOld ? 'text' : 'password'"
                                        name="input-10-2"
                                        :label="$t('settings.password.old')"
                                        value=""
                                        class="input-group--focused"
                                        @click:append="showPasswdOld = !showPasswdOld">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field v-model="password.new"
                                        :append-icon="showPasswd ? 'mdi-eye_off' : 'mdi-eye'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPasswd ? 'text' : 'password'"
                                        name="input-10-2"
                                        :label="$t('settings.password.new')"
                                        hint="At least 8 characters"
                                        value=""
                                        class="input-group--focused"
                                        @click:append="showPasswd = !showPasswd">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field v-model="password.re"
                                        :append-icon="showPasswd ? 'mdi-eye_off' : 'mdi-eye'"
                                        :rules="[rules.required, comparePasswords]"
                                        :type="showPasswd ? 'text' : 'password'"
                                        name="input-10-3"
                                        :label="$t('settings.password.retype')"
                                        value=""
                                        class="input-group--focused"
                                        @click:append="showPasswd = !showPasswd">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5">
                            <v-btn color="warning"
                                :disabled="!valid"
                                @click="changePassword">
                                {{ $t('settings.password.change') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-title>
        </v-card>

        <v-card hover
                min-height="30em"
                class="ma-2 pa-4 settings-card">
            <v-card-title primary-title>
                <v-icon large class="mr-3">mdi-robot</v-icon>
                <h3 class="headline mb-0">{{ $t('settings.avatar.change') }}</h3>
            </v-card-title>

            <v-card-title>
                <v-layout row wrap>
                    <div v-for="idx of [1,2,3,4,5,6,7,8]"
                        :key="idx"
                        :style="selectedAvatar === idx ? 'border: 5px solid rgba(255, 152, 0, 1)' : 'border: 5px solid rgba(255, 152, 0, 0)'"
                        class="ma-3">
                        <v-img :src="require('../assets/icons/'+idx+'.png')"
                            height="5em"
                            width="5em"
                            @click="changeAvatar(idx)"></v-img>
                    </div>
                </v-layout>
            </v-card-title>
        </v-card>

        <v-card hover
                min-height="30em"
                class="ma-2 pa-4 settings-card">

            <v-card-title primary-title>
                <v-icon large class="mr-3">mdi-translate-variant</v-icon>
                <h3 class="headline mb-0">{{ $t('settings.language.change') }}</h3>
            </v-card-title>

            <v-form ref="form_locale"
                    style="width:100%"
                    v-model="valid_locale"
                    lazy-validation>
                <v-row>
                    <v-card-title>
                        {{ $t('Language') }}
                        <v-select v-model="$i18n.locale"
                            prepend-icon="mdi-translate"
                            :items="langs">
                        </v-select>
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-card-title>
                        {{ $t('Timezone') }}
                        <v-select v-model="timezone"
                            prepend-icon="mdi-map-clock"
                            :items="timezones">
                        </v-select>
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-col cols="5">
                        <v-btn color="warning"
                            :disabled="!valid_locale"
                            @click="changeLocale">
                            {{ $t('Save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

    </v-row>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import { rest } from '../_helpers'

export default {
    data() {
        return {
            password: {
                old: '',
                new: '',
                re: '',
            },
            valid: false,
            valid_locale: false,
            timezones: moment.tz.names(),
            timezone: 'Europe/Berlin',
            showPasswd: false,
            showPasswdOld: false,
            passwordError: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
            },
            langs: [
                {
                    text: `${this.$t('languages.en')} (${this.$t('languages_short.en')})`,
                    value: 'en',
                },
                {
                    text: `${this.$t('languages.de')} (${this.$t('languages_short.de')})`,
                    value: 'de',
                },
            ],
        }
    },

    computed: {
        ...mapState('account', ['user']),

        selectedAvatar() {
            return this.user.user.avatar
        },
    },

    methods: {
        ...mapActions({
            clearAlert: 'alert/clear',
            showSpinner: 'page/showSpinner',
            setAvatar: 'account/setAvatar',
            setLocale: 'account/setLocale',
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),
        changePassword() {
            if (this.$refs.form.validate()) {
                rest.setPassword(this.password)
                    .then(response => {
                        if (response.data.error) {
                            this.passwordError = response.data.error
                        } else {
                            this.passwordError = ''
                            this.$refs.form.reset()

                            this.setSnackbar({
                                type: 'success',
                                text: `${this.$t('Record Saved')}`,
                            })
                            this.showSnackbar(true)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },

        changeAvatar(num) {
            rest.changeUserSettings({ avatar: num })
                .then(() => {
                    this.setAvatar(num)

                    this.setSnackbar({
                        type: 'success',
                        text: `${this.$t('Record Saved')}`,
                    })
                    this.showSnackbar(true)
                })
        },

        changeLocale() {
            if (this.$refs.form_locale.validate()) {
                const payload = { lang: this.$i18n.locale, timezone: this.timezone }
                rest.changeUserSettings(payload)
                    .then(() => {
                        this.setLocale(payload)

                        this.setSnackbar({
                            type: 'success',
                            text: `${this.$t('Record Saved')}`,
                        })
                        this.showSnackbar(true)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },

        comparePasswords() {
            return this.password.new === this.password.re || 'Password differ'
        },
    },
}
</script>


<style lang="stylus">
    .settings-card
        min-height 30em
        width 28em

    @media (max-width: 959px)
        .settings-card
            width 100%
</style>
